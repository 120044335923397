<template>
  <div class="guidance-edit">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="标题：" prop="title">
        <el-input class="title" type="input" v-model.trim="form.title" placeholder="标题默认加粗"></el-input>
      </el-form-item>
      <el-form-item label="文章内容：" prop="content">
        <el-input class="code" type="textarea" v-model.trim="form.content" placeholder="在此输入"></el-input>
      </el-form-item>
      <p class="label">封面和摘要：</p>
      <div class="d-flex w-100">
        <el-form-item prop="coverImage">
          <upLoad @setImageUrl="setImageUrl" :coverImage="form.coverImage" />
        </el-form-item>
        <el-form-item style="flex:1">
          <el-input class="abstract" type="textarea" v-model.trim="form.abstract" placeholder="选填，摘要会在文章外显露，帮助读者快速了解内容。" maxlength="120" show-word-limit></el-input>
        </el-form-item>
      </div>
      <el-form-item label="对谁可见：" prop="endpoints">
        <el-radio class="ml-4 pl-3" label="requester" :value="requester" @click.native.prevent="endpointsSelect('requester')">发布者</el-radio>
        <el-radio label="worker" :value="worker" @click.native.prevent="endpointsSelect('worker')">标注员</el-radio>
        <el-radio label="app" :value="app" @click.native.prevent="endpointsSelect('app')">app</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button class="border-button" size="small" @click="submitAndBack">保存</el-button>
        <el-button class="border-button" size="small" @click="visible = true">预览</el-button>
        <el-button v-if="publish" class="submit-button" size="small" @click="submit">发布</el-button>
      </el-form-item>
    </el-form>
    <preview-equipment
      :title="form.title"
      :content="form.content"
      :visible.sync="visible"
    ></preview-equipment>
  </div>
</template>

<script>
import PreviewEquipment from '@/components/PreviewEquipment'
import upLoad from '../../components/fanhan-upload.vue'
import {
  guideEdit,
  guideInfo,
  guidePublish
} from '@/api/tips'
export default {
  name: 'guidance-edit',
  components: {
    PreviewEquipment,
    upLoad
  },
  created () {
    const { meta, query } = this.$route
    if (
      meta.name === 'edit' &&
      query.id
    ) {
      this.id = Number(query.id)
      this.publish = query.ptime === '0'
      guideInfo(this.id).then(
        res => {
          this.form = res.data
        }
      )
    }
  },
  data () {
    const checkEndpoints = (rule, value, callback) => {
      if (value.every(v => v === '')) {
        callback(new Error('请选择对谁可见'))
      } else {
        callback()
      }
    }
    return {
      id: '',
      publish: true,
      visible: false,
      form: {
        title: '',
        content: '',
        coverImage: '',
        abstract: '',
        endpoints: []
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' }
        ],
        coverImage: [
          { required: true, message: '请上传封面', trigger: '' }
        ],
        endpoints: [
          { validator: checkEndpoints, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    requester () {
      return this.findPort('requester')
    },
    worker () {
      return this.findPort('worker')
    },
    app () {
      return this.findPort('app')
    },
    lastData () {
      const data = {
        ...this.form
      }
      if (this.id) data.id = this.id
      return data
    }
  },
  methods: {
    findPort (port) {
      return this.form.endpoints.indexOf(port) !== -1 ? port : ''
    },
    setImageUrl (url) {
      this.form.coverImage = url
    },
    guideEdit () {
      return guideEdit(this.lastData)
    },
    submitAndBack () {
      this.guideEdit().then(
        () => {
          this.$router.go(-1)
        }
      )
    },
    endpointsSelect (role) {
      const index = this.form.endpoints.indexOf(role)
      if (index === -1) {
        this.form.endpoints.push(role)
      } else {
        this.form.endpoints.splice(index, 1)
      }
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.guideEdit().then(
            res => {
              return guidePublish(res.data.id)
            }
          ).then(
            () => {
              this.$notify({
                title: '成功',
                message: '发布成功',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.replace('/tips-admin/play-fanhan/new-guidance')
              }, 500)
            }
          )
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.guidance-edit {
  padding-top: 20px;
  /deep/.el-input__inner {
    border-radius: 2px;
  }
  .code /deep/.el-textarea__inner {
    height: 350px;
    border-radius: 2px;
  }
  .abstract {
    margin-left: 20px;
    /deep/.el-textarea__inner {
      height: 100px;
      border-radius: 2px;
    }
  }
  .label,
  /deep/.el-form-item__label {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
  }
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: ''
}
</style>
